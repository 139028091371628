import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDPrQUHW5P8AFJa8x4tBLhz04Qc8WfUWkM",
  authDomain: "web3-5d76c.firebaseapp.com",
  databaseURL: "https://web3-5d76c-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "web3-5d76c",
  storageBucket: "web3-5d76c.firebasestorage.app",
  messagingSenderId: "995016308479",
  appId: "1:995016308479:web:bbfc777e250cc6fdeebeca",
  measurementId: "G-2WXFPKMJX1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);